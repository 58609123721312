import Image from 'next/image';
import router from 'next/router';
import Link from 'next/link';
import type { GetServerSideProps, NextPage } from 'next';
import toast from 'react-hot-toast';

import Form from '@client/components/Form';
import Box from '@client/components/Box';
import Text from '@client/components/Text';

import LoginForm from '@client/containers/LoginForm';
import { login } from '@client/services/AuthService';
import User from '@client/entities/User';
import { withSessionSsr } from '@client/utils/PromiseUtils';
import { useRef, useState } from 'react';

const Index: NextPage = () => {
  const recaptchaRef: any = useRef();
  const [recaptcha, setRecaptcha] = useState(false);

  const eventSubmit = async (data: User) => {
    const token = await recaptchaRef.current.getValue();

    if (recaptcha) {
      if (token) {
        const response = await login({ ...data, captcha: token });

        if (
          response.status === '0001' &&
          ['ROLE_SUPERADMIN', 'ROLE_FINANCE', 'ROLE_OPERATION'].includes(
            response.data.userdetail.role,
          )
        ) {
          router.push('/dashboard');
        } else {
          toast.error(response.message);
        }
      }
    }

    recaptchaRef.current.reset();
  };

  return (
    <>
      <Box as="section" className="log-reg" style={{ height: '100vh' }}>
        <Box className="overlay d-flex align-items-center">
          <Box className="container">
            <Box className="top-head-area">
              <Box className="row d-flex align-items-center">
                <Box className="col-sm-5 col">
                  <a className="back-home" href="#">
                    <Image
                      src="/images/left-icon.png"
                      alt="image"
                      width={16}
                      height={14}
                    />
                    <Text as="span" margin="0 10px 0 0" color="#fff">
                      Back to Home
                    </Text>
                  </a>
                </Box>
                <Box className="col-sm-5 col">
                  <Link
                    href="/"
                    style={{ marginLeft: '-5px', marginTop: '50px' }}
                  >
                    <Image
                      src="/images/logo_wide_alt.svg"
                      alt="image"
                      width={230}
                      height={70}
                    />
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box className="row justify-content-center">
              <Box className="col-lg-5">
                <Box className="form-box px-5">
                  <Text as="h4" className="text-center mb-4">
                    Login to Pazemo
                  </Text>

                  <Form
                    render={LoginForm}
                    onSubmit={eventSubmit}
                    values={User}
                    recaptchaRef={recaptchaRef}
                    eventRecaptcha={(data: boolean) => setRecaptcha(data)}
                    recaptcha={recaptcha}
                  />

                  <Box className="form-bottom">
                    <Box className="forget-pw text-center">
                      <Link href="/forgot-password">
                        <a>Forgot your password?</a>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = withSessionSsr(
  async function getServerSideProps({ req }: any) {
    const session = req.session?.user ?? null;

    if (
      session &&
      session.isLogin &&
      ['ROLE_SUPERADMIN', 'ROLE_FINANCE', 'ROLE_OPERATION'].includes(
        session.role,
      )
    ) {
      return {
        redirect: {
          destination: '/dashboard',
          permanent: false,
        },
      };
    }

    return {
      props: {
        name: 'login',
        protected: false,
        session: session,
      },
    };
  },
);

export default Index;
